<template>
	<b-card-actions
		title="Filter"
		action-collapse
		:collapsed="false"
	>
		<b-row class="filter">
			<!-- Code -->
			<b-col
				cols="12"
				md="3"
				class="mb-2"
			>
				<label>Code</label>
				<b-form-input
					:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
					v-model="filter.codeFilter"
					class="w-100"
					placeholder="Enter Code"
				/>
			</b-col>
			<!-- Group Name -->
			<b-col
				cols="12"
				md="3"
				class="mb-md-0 mb-2"
			>
				<label>Group Name</label>
				<v-select
					:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
					v-model="filter.groupIdFilter"
					:options="groupNameOptions"
					class="w-100"
					placeholder="Select Group Name"
					:reduce="val => val.id"
					label="name"
				/>
			</b-col>
			<!-- Currency -->
			<b-col
				cols="12"
				md="3"
				class="mb-md-0 mb-2"
			>
				<label>Currency</label>
				<v-select
					:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
					v-model="filter.currencyFilter"
					:options="currencyOptions"
					class="w-100"
					placeholder="Select Currency"
					:reduce="val => val.value"
					label="value"
				/>
			</b-col>
			<!-- Product -->
			<b-col
				cols="12"
				md="3"
				class="mb-md-0 mb-2"
			>
				<label>Product</label>
				<v-select
					:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
					v-model="productIdFilter"
					:options="productOptions"
					class="w-100"
					placeholder="Select Product"
					:reduce="val => val.id"
					label="name"
				/>
			</b-col>
			<!-- Type -->
			<b-col
				cols="12"
				md="3"
				class="mb-md-0 mb-2"
			>
				<label>Type</label>
				<v-select
					:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
					v-model="filter.discountTypeFilter"
					:options="typeOptions"
					class="w-100"
					placeholder="Select Type"
					:reduce="val => val.value"
				/>
			</b-col>
			<!-- Status -->
			<b-col
				cols="12"
				md="3"
				class="mb-2"
			>
				<label>Status</label>
				<v-select
					:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
					v-model="filter.statusFilter"
					:options="statusOptions"
					class="w-100"
					placeholder="Select Status"
					:reduce="val => val.value"
				/>
			</b-col>
			<!-- From -->
			<b-col
				cols="12"
				md="3"
				class="mb-md-0 mb-2"
			>
				<b-form-group
					label="From Date"
					label-for="fromDate"
				>
					<flat-pickr
						id="fromDate"
						v-model="filter.fromdateFilter"
						class="form-control"
						placeholder="YYYY-MM-DD"
					/>
				</b-form-group>
			</b-col>
			<!-- To -->
			<b-col
				cols="12"
				md="3"
				class="mb-md-0 mb-2"
			>
				<b-form-group
					label="To Date"
					label-for="toDate"
				>
					<flat-pickr
						id="toDate"
						v-model="filter.todateFilter"
						class="form-control"
						placeholder="YYYY-MM-DD"
					/>
				</b-form-group>
			</b-col>
			<!-- action -->
			<b-col
				cols="12"
				class="d-flex justify-content-end"
			>
				<b-button
					variant="outline-primary"
					@click="resetFilter()"
				>
					Reset
				</b-button>
				<b-button
					variant="primary"
					class="ml-2"
					@click="searchFilter()"
				>
					Search
				</b-button>
			</b-col>
		</b-row>
	</b-card-actions>
</template>

<script>
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import {
	BFormDatepicker, BInputGroup, BInputGroupAppend, BCardText, BFormGroup, BFormInput, BCard, BCardHeader, BCardBody, BRow, BCol, BButton,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import 'flatpickr/dist/flatpickr.css'
export default {
	components: {
		BCardActions,
		BButton,
		BRow,
		BCol,
		BCard,
		BFormGroup,
		BFormInput,
		BCardHeader,
		BCardBody,
		BCardText,
		BFormDatepicker,
		BInputGroup,
		BInputGroupAppend,
		BFormInput,
		vSelect,
		flatPickr,
	},
	props: {
		codeFilter: {
			type: [String, null],
			default: null,
		},
		groupIdFilter: {
			type: [Number, null],
			default: null,
		},
		currencyFilter: {
			type: [String, null],
			default: null,
		},
		productIdFilter: {
			type: [Number, null],
			default: null,
		},
		discountTypeFilter: {
			type: [Number, null],
			default: null,
		},
		fromdateFilter: {
			type: [String, null],
			default: null,
		},
		todateFilter: {
			type: [String, null],
			default: null,
		},
		statusFilter: {
			type: [Number, null],
			default: null,
		},
		statusOptions: {
			type: Array,
			required: true,
		},
		groupNameOptions: {
			type: Array,
			required: true,
		},
		currencyOptions: {
			type: Array,
			required: true,
		},
		productOptions: {
			type: Array,
			required: true,
		},
		typeOptions: {
			type: Array,
			required: true,
		},
	},
	data() {
		return {
			filter: {
				codeFilter: this.codeFilter,
				groupIdFilter: this.groupIdFilter,
				currencyFilter: this.currencyFilter,
				productIdFilter: this.productIdFilter,
				discountTypeFilter: this.discountTypeFilter,
				fromdateFilter: this.fromdateFilter,
				todateFilter: this.todateFilter,
				statusFilter: this.statusFilter,
			},
		};
	},
	methods: {
		resetFilter() {
			this.filter = {
				codeFilter: null,
				groupIdFilter: null,
				currencyFilter: null,
				productIdFilter: null,
				discountTypeFilter: null,
				fromdateFilter: null,
				todateFilter: null,
				statusFilter: null,
			}
			this.$emit('update:codeFilter', null)
			this.$emit('update:groupIdFilter', null)
			this.$emit('update:currencyFilter', null)
			this.$emit('update:productIdFilter', null)
			this.$emit('update:discountTypeFilter', null)
			this.$emit('update:statusFilter', null)
			this.$emit('update:fromdateFilter', null)
			this.$emit('update:todateFilter', null)
			this.$emit("refetch-data");
		},

		searchFilter() {
			// alert('waiting API ....');
			this.$emit('update:codeFilter', this.filter.codeFilter);
			this.$emit('update:groupIdFilter', this.filter.groupIdFilter);
			this.$emit('update:currencyFilter', this.filter.currencyFilter);
			this.$emit('update:productIdFilter', this.filter.productIdFilter);
			this.$emit('update:discountTypeFilter', this.filter.discountTypeFilter);
			this.$emit('update:statusFilter', this.filter.statusFilter);
			this.$emit('update:fromdateFilter', this.filter.fromdateFilter);
			this.$emit('update:todateFilter', this.filter.todateFilter);
			this.$emit("refetch-data");
		}
	},
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
